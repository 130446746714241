<script lang="ts" setup>
import type { MarketingFlag, OfferFull, OfferMedium } from '~/types/NewTypes/CatalogNew';

const props = defineProps<{ offer: OfferMedium | OfferFull; usedIn: 'category' | 'offer' }>();

const selectedFlag = ref<MarketingFlag | null>(null);
const isShowFlagModal = ref(false);

watch(
  () => selectedFlag.value,
  () => (isShowFlagModal.value = !!selectedFlag.value),
);

function checkFlagDisplay(flag: MarketingFlag): boolean {
  switch (props.usedIn) {
    case 'category':
      return flag.show_in_categories ?? true;
    case 'offer':
      return flag.show_in_offer ?? true;
    default:
      return true;
  }
}
</script>

<template>
  <section class="product-image-badges">
    <template
      v-for="flag in offer.marketing_flags"
      :key="flag.title"
    >
      <component
        :is="flag.description ? 'button' : 'div'"
        v-if="checkFlagDisplay(flag)"
        :style="{
          'border-color': flag.border_color ?? '#393d46',
          'background-color': flag.background_color ?? 'transparent',
          color: flag.text_color ?? '#393d46',
        }"
        class="product-image-badges__item"
        @click="selectedFlag = flag"
      >
        {{ flag.title }}
      </component>
    </template>

    <BaseModal
      v-model="isShowFlagModal"
      :title="selectedFlag?.title"
      confirm-btn-text="Понятно"
      @close="selectedFlag = null"
      @confirm="selectedFlag = null"
    >
      <div v-html="selectedFlag?.description" />
    </BaseModal>

    <div
      v-for="badge in offer.marketing_badges"
      :key="badge.title"
      :style="{
        'border-color': badge.border_color ?? '#393d46',
        'background-color': badge.background_color ?? 'transparent',
        color: badge.text_color ?? '#393d46',
      }"
      class="product-image-badges__item"
    >
      {{ badge.title }}
    </div>
  </section>
</template>

<style lang="scss" scoped>
.product-image-badges {
  position: absolute;
  z-index: 5;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__item {
    width: fit-content;
    padding: 2px;
    border: 2px solid;
    border-radius: 4px;
    font-weight: $medium;
    font-size: $text-2xs;
  }
}
</style>
