<script lang="ts" setup>
import { Dropdown } from 'floating-vue';

defineEmits(['confirm']);
defineProps<{
  text?: string;
  okBtnText?: string;
  cancelBtnText?: string;
}>();

const ariaId = useId();
</script>

<template>
  <Dropdown :aria-id="ariaId">
    <!-- кнопка активатор -->
    <slot />

    <!-- контент дропдауна -->
    <template #popper="{ hide }">
      <div class="confirmation-dropdown">
        <p class="text-sm">{{ text ?? 'Подтвердите действие' }}</p>

        <div class="flex gap-3">
          <TheBtn
            size="sm"
            @click="hide()"
          >
            {{ cancelBtnText ?? 'Отмена' }}
          </TheBtn>

          <TheBtn
            color="secondary"
            size="sm"
            @click="$emit('confirm')"
          >
            {{ okBtnText ?? 'ОК' }}
          </TheBtn>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<style lang="scss">
.v-popper--theme-dropdown .v-popper__inner {
  border-radius: 16px;
}

.confirmation-dropdown {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: 8px;
  border-radius: 12px;
}
</style>
