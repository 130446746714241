<script lang="ts" setup>
import type { OfferBalance, OfferFull, OfferMedium } from '~/types/NewTypes/CatalogNew';

const props = defineProps<{ offer: OfferFull | OfferMedium }>();

const isInStock = computed(() => {
  let counter = 0;

  props.offer.offer_balances.forEach((balance: OfferBalance) => {
    counter += balance.balance;
  });

  return !!counter;
});
</script>

<template>
  <div class="flex items-center gap-1.5">
    <div
      :class="{ 'availability-indicator--available': isInStock }"
      class="availability-indicator"
    />

    {{ isInStock ? 'В' : 'Нет в' }} наличии
  </div>
</template>

<style lang="scss" scoped>
.availability-indicator {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: $hell-red;

  &--available {
    background-color: $hell-green;
  }
}
</style>
