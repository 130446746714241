<script lang="ts" setup>
import type { OfferFull, OfferMedium } from '~/types/NewTypes/CatalogNew';
import type { Offer } from '~/types/Cart';

const props = defineProps<{ offer: OfferMedium | OfferFull | Offer }>();
const catalogStore = useCatalogStore();
const { sendOfferEventToDataLayer } = useGtmModule();

const isAddedToFavorites = computed(() => catalogStore.favoriteOffersIds.includes(props.offer.id));
const isLoading = ref(false);

async function switchFavoriteState() {
  if (isLoading.value) return;

  isLoading.value = true;

  await catalogStore.toggleFavorite(props.offer.id);

  sendOfferEventToDataLayer(isAddedToFavorites.value ? 'add_to_wishlist' : 'remove_from_wishlist', props.offer);
  window.roistat?.event?.send(isAddedToFavorites.value ? 'add_to_wishlist' : 'remove_from_wishlist');
  if (window.r46) window.r46('track', isAddedToFavorites.value ? 'wish' : 'remove_wish', props.offer.id);

  isLoading.value = false;
}
</script>

<template>
  <button
    :disabled="isLoading"
    name="favorite-button"
    @click.stop.prevent="switchFavoriteState"
  >
    <SvgoHeart
      :class="isAddedToFavorites ? 'fill-label-red' : 'fill-white'"
      class="text-2xl text-hell-black-60 hover:fill-hell-black-20 transition"
      filled
    />
  </button>
</template>
